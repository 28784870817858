/*
Import global styles
Could also be done in angular.json
Importing the styles here results in one global css file
https://stackoverflow.com/questions/55309150/importing-styles-in-angular-json-vs-importing-in-styles-css
*/
@import "normalize.css/normalize.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/*
Easiest way to include material icons is using the font hosted by Google
https://google.github.io/material-design-icons/#icon-font-for-the-web
*/
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

/* Main elements */
html,
body,
material-app {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #f9f8f6;
  color: #333;
  font-family: "IBM Plex Sans", sans-serif;
}

body {
    font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

/* Headers */
h1,
h2,
h3,
h4,
button {
  font-family: IBM Plex Sans, sans-serif;
}

h1 {
    font-weight: 600;
    font-size: 3.1em;
}

h2 {
  font-weight: 600;
  font-size: 2.2em;
}


/* Defined this globally because the overlay is outside the components scope */
.product-tooltip {
  font-size: 1.8em;
  max-width: 500px !important;
}

/* Also defined this globally because the mat-paginator is outside the components scope */
@media only screen and (max-width: 768px) {
  .mat-paginator-container {
    justify-content: center;
    flex-direction: column;
  }
  .mat-paginator-range-actions {
    flex-wrap: wrap;
    justify-content: center;
  }
  .mat-paginator-range-label {
    width: 100%;
    text-align: center;
    order: 99;
    padding-top: 15px;
  }
}

.mat-dialog-container {
  padding: 30px!important;
  overflow: auto !important;
  max-height: 80vh !important;
  background-color: #fff;
}

/*Ditto for mat-select*/
.mat-select-trigger * {
  font-family: "IBM Plex Sans", sans-serif;
}

.mat-select-trigger {
  height: 100%;
}

.mat-select-value {
  padding-top: 10px;
}

.mat-option {
  background-color: white;
  &:hover {
    background-color: lightgray;
  }
}


.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
  background: #1f9f44;
}

.mat-tooltip {
  font-size: 1.4em;
}

.remove-moval-padding
{
@media only screen and (max-width: 768px)
    {
      width:100%!important;
      overflow-y: visible!important;
    }
  }

.remove-moval-padding .mat-dialog-container
{
    padding:0px!important;

    @media only screen and (max-width: 768px)
    {
      width:100%;
      overflow:scroll!important;
    }
}

.cursor-pointer {
  cursor: pointer;
}
